import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Grid, Stack, Typography, Link } from "@mui/material";


const content = {
  Philip: `Philip Thomas (b.1972, North Devon) is a Professor of Performance, having joined the University of Huddersfield in 2005. He specialises in performing and writing about new and experimental music, including both notated and improvised music. He places much emphasis on each concert being a unique event, designing imaginative programmes that provoke and suggest connections. You can find out more about Philip's work and forthcoming events <a href="http://www.philip-thomas.co.uk/"">here</a>.`,
  Martin: `Martin Iddon is a composer and musicologist. He joined the staff at Leeds in December 2009, having previously lectured at University College Cork and Lancaster University. He studied composition and musicology at the Universities of Durham and Cambridge, and has also studied composition privately with Steve Martland, Chaya Czernowin, and Steven Kazuo Takasugi. His musicological research has largely focussed on post-war music in Germany and the United States of America. His books <a href="https://doi.org/10.1017/CBO9781139519571"><em>New Music at Darmstadt</em></a>, <a href="https://doi.org/10.1017/CBO9781139013727"><em>John Cage and David Tudor</em></a>, and <a href="https://www.cambridge.org/core/books/john-cage-and-peter-yates/2E0F6EC8D5146B811AAB3A5174C7DF8F"><em>John Cage and Peter Yates</em></a> are published by Cambridge University Press.`,
  Chris: `Dr Christopher Melen's main role within the project is the development of an interactive website, but also covers collation and analysis of the work's materials. Christopher studied music at Cardiff University, being awarded a BMus (1st class) in 2001, then continued at Cardiff with a PhD in Composition, under the supervision of distinguished composer Anthony Powers. He has a long-standing interest in computer-aided composition, and has used <a href="http://www.ircam.fr/?&L=1">IRCAM</a>'s <a href="http://repmus.ircam.fr/openmusic/home">OpenMusic</a> computer-aided composition environment extensively. Since leaving Cardiff in 2006 Christopher worked mainly as a programmer and web developer, working most recently for the award-winning US text analytics company <a href="http://gosynapsify.com/">Synapsify</a>.`,
  Emily: `Dr Emily Payne was a Postdoctoral Research Assistant on the project, and now works as a Lecturer in Music at the University of Leeds. Emily undertook her PhD at the University of Oxford, employing an ethnographic approach to examine creativity in clarinet performance. Her work is published in Contemporary Music Review, cultural geographies, Musicae Scientiae, and Music & Letters. She is co-editor of the Handbook of Time in Music, under contract with Oxford University Press. Emily also holds the role of Editorial Assistant at <a href="http://journals.sagepub.com/home/mns"><em>Music & Science</em></a>.`,
};

/*
const GridItems = () => {
  return content.map(item => {
    const { src, title, subtitle, text } = item;
    return (
      <>
        <Grid item xs={6}>
          <div><StaticImage src={src} alt={title}/></div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Typography variant="h1">{title}</Typography>
            <Typography variant="h2">{subtitle}</Typography>
            <p>{text}</p>
          </div>
        </Grid>
      </>
    )
  });
}

const DynamicStaffList = () => {
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <GridItems />
      </Grid>
    </Box>
  );
}
*/

const StaffList = () => {

  const linkStyle = {
    textDecoration: "none",
  }

  const h4Style = {
    paddingTop: 6,
  }

  const pStyle = {
    paddingTop: 12,
  }

  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={3}>
          <div><StaticImage src="../images/Philip-Thomas.jpg" alt="Philip Thomas"/></div>
        </Grid>
        <Grid item xs={9}>
          <div>
            <Typography variant="h3">
              <Link href="https://pure.hud.ac.uk/en/persons/philip-thomas" style={ linkStyle }>Philip Thomas</Link>
            </Typography>
            <Typography variant="h4" style={ h4Style }>Principal Investigator</Typography>
            <p dangerouslySetInnerHTML={{ __html: content["Philip"] }} style={ pStyle }></p>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div><StaticImage src="../images/Martin-Iddon.jpeg" alt="Martin Iddon"/></div>
        </Grid>
        <Grid item xs={9}>
          <div>
            <Typography variant="h3">
              <Link href="https://www.martiniddon.com/" style={ linkStyle }>Martin Iddon</Link>
            </Typography>
            <Typography variant="h4" style={ h4Style }>Co-Investigator</Typography>
            <p dangerouslySetInnerHTML={{ __html: content["Martin"] }} style={ pStyle }></p>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div><StaticImage src="../images/Chris-Melen.jpeg" alt="Christopher Melen"/></div>
        </Grid>
        <Grid item xs={9}>
          <div>
            <Typography variant="h3">
              <Link href="http://www.christophermelen.com/" style={ linkStyle }>Christopher Melen</Link>
            </Typography>
            <Typography variant="h4" style={ h4Style }>Research Assistant</Typography>
            <p dangerouslySetInnerHTML={{ __html: content["Chris"] }} style={ pStyle }></p>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div><StaticImage src="../images/Emily-Payne.jpg" alt="Emily Payne"/></div>
        </Grid>
        <Grid item xs={9}>
          <div>
            <Typography variant="h3">
              <Link href="https://ahc.leeds.ac.uk/music/staff/396/dr-emily-payne" style={ linkStyle }>Emily Payne</Link>
            </Typography>
            <Typography variant="h4" style={ h4Style }>Postdoctoral Research Assistant</Typography>
            <p dangerouslySetInnerHTML={{ __html: content["Emily"] }} style={ pStyle }></p>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StaffList;