import VideoPlayer from "../../../../src/components/cloudflare-player";
import pianoChapters from "../../../../src/assets/video-chapters/piano.json";
import PlaceholderImg from "../../../../src/markdown/performing/images/video-placeholders/philip-interview-m4v-image.jpg";
import Citations from "../../../../src/components/citations";
import * as React from 'react';
export default {
  VideoPlayer,
  pianoChapters,
  PlaceholderImg,
  Citations,
  React
};