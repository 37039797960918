import VideoPlayer from "../../../../src/components/cloudflare-player";
import clarinetChapters from "../../../../src/assets/video-chapters/clarinet.json";
import VickyInterviewPlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/vicky-interview-m4v-image.jpg";
import VickyPerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/vicky-performance-m4v-image.jpg";
import Citations from "../../../../src/components/citations";
import * as React from 'react';
export default {
  VideoPlayer,
  clarinetChapters,
  VickyInterviewPlaceholderImg,
  VickyPerformancePlaceholderImg,
  Citations,
  React
};