import VideoPlayer from "../../../../src/components/cloudflare-player";
import tubaChapters from "../../../../src/assets/video-chapters/tuba.json";
import MelvynInterviewPlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/melvyn-interview-m4v-image.jpg";
import MelvynPerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/melvyn-performance-m4v-image.jpg";
import Citations from "../../../../src/components/citations";
import * as React from 'react';
export default {
  VideoPlayer,
  tubaChapters,
  MelvynInterviewPlaceholderImg,
  MelvynPerformancePlaceholderImg,
  Citations,
  React
};