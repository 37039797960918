// From https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/
const stripTags = (str) => str.replace(/(<([^>]+)>)/gi, "");

const pad = (n) => n<10 ? '0'+n : n;

const seconds2HhMmSs = (seconds) => {
  const secs = pad(Math.floor(seconds % 60));
  const mins = pad(Math.floor((seconds / 60) % 60));
  const hrs = pad(Math.floor((mins / 60) % 60));
  return `${hrs}:${mins}:${secs}`;
}

const getPage = (slug, host="https://cageconcert.org/") => {
  const url = new URL(slug, host);
  const { pathname } = url;
  return pathname.split("/").filter(elt => elt!=="").slice(-1)[0];
}

module.exports = {
  stripTags,
  pad,
  seconds2HhMmSs,
  getPage,
}