import VideoPlayer from "../../../../src/components/cloudflare-player";
import bassoonChapters from "../../../../src/assets/video-chapters/bassoon.json";
import ChristianInterviewPlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/christian-interview-m4v-image.jpg";
import ChristianPerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/christian-performance-harderFIX-m4v-image.jpg";
import Citations from "../../../../src/components/citations";
import * as React from 'react';
export default {
  VideoPlayer,
  bassoonChapters,
  ChristianInterviewPlaceholderImg,
  ChristianPerformancePlaceholderImg,
  Citations,
  React
};