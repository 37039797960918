import React, { useState, useRef } from "react";
import { Box, Stack, List, ListItem, ListItemButton, ListItemText, } from "@mui/material";
import ReactPlayer from "react-player";
import { seconds2HhMmSs } from "../utils";


// Chapter List.
const Chapters = ({ playerRef, chapters }) => {

  const chapterListRef = useRef();

  const handleChangeChapter = async (e) => {
    const player = playerRef.current;
    if (player) {
      const time = +e.currentTarget.id;
      player.seekTo(time);
      await player.getInternalPlayer().play();
    }
  }

  const lsBtnSx = {
    p: "0px",
    "&:hover": {
      color: "#595d60",
      background: "none"
    }
  };

  const cueStartTimeStyle = {
    color: "rgb(8, 150, 254)",
    marginRight: 8,
  }

  const buildChapterList = () => {
    return chapters.map((chapter) => {
      const { title, startTime } = chapter;
      const cueTime = seconds2HhMmSs(startTime);
      return (
        <div key={title}>
          <ListItem disableGutters style={ { padding: "0px", margin: "0px" } }>
            <ListItemButton id={startTime} onClick={handleChangeChapter} sx={lsBtnSx}>
              <ListItemText primary={<span><span style={ cueStartTimeStyle }>{cueTime}</span><span>{title}</span></span>}/>
            </ListItemButton>
          </ListItem>
        </div>
      );
    });
  }

  return (
    <List id="chapters" ref={chapterListRef} dense={true}>
      { buildChapterList() }
    </List>
  );
}

// Player.
const CloudflarePlayer = ({ id, src, chapters, placeholderImg, }) => {

  const playerRef = useRef();

  const [isLoaded, setIsLoaded] = useState(false);

  const onReady = () => {
    if (playerRef.current) {
      setIsLoaded(true);
    }
  }

  const code = "blnui0yzmwbyf4g8";
  const url = `https://customer-${code}.cloudflarestream.com/${src}/manifest/video.m3u8`;

  return (
    <Stack>
      <Box id={id}>
        <ReactPlayer
          ref={playerRef}
          url={url}
          controls={true}
          width="100%"
          height="auto"
          config={{ file: {
            attributes: {
              controlsList: "nodownload noplaybackrate",
              poster: placeholderImg,
            },
            forceHLS: true, // Do we need this?
          }}}
          onReady={onReady}
        />
      </Box>
      { chapters && isLoaded && <Chapters playerRef={playerRef} chapters={chapters}/> }
    </Stack>
  )
}

export default CloudflarePlayer;
