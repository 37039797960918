import VideoPlayer from "../../../../src/components/cloudflare-player";
import fluteChapters from "../../../../src/assets/video-chapters/flute.json";
import NancyInterviewPlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/nancy-interview-m4v-image.jpg";
import NancyPerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/nancy-performance-m4v-image.jpg";
import Citations from "../../../../src/components/citations";
import * as React from 'react';
export default {
  VideoPlayer,
  fluteChapters,
  NancyInterviewPlaceholderImg,
  NancyPerformancePlaceholderImg,
  Citations,
  React
};