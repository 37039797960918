import Citations from "../../../../src/components/citations";
import VideoPlayer from "../../../../src/components/cloudflare-player";
import stringChapters from "../../../../src/assets/video-chapters/strings.json";
import StringsInterviewPlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/strings-m4v-image.jpg";
import Violin1PerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/violin-1-performance-m4v-image.jpg";
import Violin2PerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/violin-2-performance-m4v-image.jpg";
import Violin3PerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/violin-3-performance-m4v-image.jpg";
import Viola1PerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/viola-1-performance-m4v-image.jpg";
import Viola2PerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/viola-2-performance-m4v-image.jpg";
import CelloPerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/cello-performance-m4v-image.jpg";
import BassPerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/bass-performance-m4v-image.jpg";
import * as React from 'react';
export default {
  Citations,
  VideoPlayer,
  stringChapters,
  StringsInterviewPlaceholderImg,
  Violin1PerformancePlaceholderImg,
  Violin2PerformancePlaceholderImg,
  Violin3PerformancePlaceholderImg,
  Viola1PerformancePlaceholderImg,
  Viola2PerformancePlaceholderImg,
  CelloPerformancePlaceholderImg,
  BassPerformancePlaceholderImg,
  React
};