import VideoPlayer from "../../../../src/components/cloudflare-player";
import trumpetChapters from "../../../../src/assets/video-chapters/trumpet.json";
import JonathanInterviewPlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/jonathan-interview-m4v-image.jpg";
import JonathanPerformancePlaceholderImg from "../../../../src/markdown/performing/orchestra/images/video-placeholders/jonathan-performance-harderFIX-m4v-image.jpg";
import Citations from "../../../../src/components/citations";
import * as React from 'react';
export default {
  VideoPlayer,
  trumpetChapters,
  JonathanInterviewPlaceholderImg,
  JonathanPerformancePlaceholderImg,
  Citations,
  React
};