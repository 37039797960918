import VideoPlayer from "../../../../src/components/cloudflare-player";
import conductorChapters from "../../../../src/assets/video-chapters/conductor.json";
import InterviewPlaceholderImg from "../../../../src/markdown/performing/images/video-placeholders/conductor-interview-m4v-image.jpg";
import PerformancePlaceholderImg from "../../../../src/markdown/performing/images/video-placeholders/conductor-performance-m4v-image.jpg";
import * as React from 'react';
export default {
  VideoPlayer,
  conductorChapters,
  InterviewPlaceholderImg,
  PerformancePlaceholderImg,
  React
};