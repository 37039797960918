import React from 'react';
import { MDXProvider } from "@mdx-js/react";


const citationStyle = {
  marginLeft: ".5in",
  textIndent: "-.5in",
}

function Citations({ children }) {
  return (
    <MDXProvider
      components={{
        p: (props) => <p {...props} style={ citationStyle } />
      }} 
    >
      <div>{children}</div>
    </MDXProvider>
  );
}

export default Citations;